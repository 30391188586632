import React, {useEffect, useState} from 'react';
import './Import.css';
import {Card, Col, Row} from "react-bootstrap";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import FileInput from "../../forms/FileInput/FileInput";
import {Button} from "../../forms/Button/Button";
import {loadEvent} from "../Categories/Categories.service";
import {LoadingRow} from "../../LoadingRow/LoadingRow";
import {importBikeReg} from "./Import.service";
import {Redirect} from "react-router-dom";

export const Import = ({match}) => {
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState();
  const [imported, setImported] = useState(false);
  useEffect(() => { loadEvent(match.params.id, setLoading).then(setEvent) }, []);

  const onClick =
    (setSpin) =>
      importBikeReg(event.id, file, setSpin)
      .then(() => {
        setError('');
        setImported(true);
      })
      .catch(e => setError(e.error));

  if(imported) return <Redirect to={`/in/events/${event.id}/entries`} />;

  return (
    !event || loading
      ? <Card><Card.Body><LoadingRow/></Card.Body></Card>
      : <DashboardPage title={event.name} year={event.year} pageTitle="Import">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title><h3 className="mb-0">BikeReg import</h3></Card.Title>
                  <Card.Text>
                    <Row>
                      <Col>
                        <FileInput onChange={setFile} value={file}/>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button onClick={(s) => onClick(s)}>Import</Button>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </DashboardPage>
  );
};
