import {api} from "../../../../services/api/api.service";

export const getNextPlace = (results, category) => {
  const bib_number = '';
  const entry = { bib_number };
  const category_id = category.id;
  const lastResult = results[results.length - 1];
  if(!lastResult) return {place: 1, category_id, entry};

  const place = isNaN(lastResult.place) ? lastResult.place : (Number.parseInt(lastResult.place, 10) + 1).toString();
  return { place, category_id, entry};
}


export const loadCategory = (id, loading) => api({uri: `/categories/${id}`, loading});
export const generateResultsFromLapsForCategory = (id, loading) => api({uri: `/categories/${id}/results_from_laps`, loading});
export const saveResult = ({id, ...result}) => {
  if (id) {
    return api(
      {
        method: 'patch',
        uri: `/results/${id}`,
        body: {result},
        loading: () => {
        }
      })
  } else {
    return api(
      {
        method: 'post',
        uri: `/categories/${result.category_id}/results`,
        body: {result},
        loading: () => {}
      });
  }
};

export const removeResult = ({id}) => api(
  {
    method: 'delete',
    uri: `/results/${id}`,
    loading: () => {}
  });