import React, {useEffect, useState} from 'react';
import './Events.css';
import {loadAccounts} from "./Events.service";
import {Card, Col, Row} from "react-bootstrap";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {LoadingRow} from "../../LoadingRow/LoadingRow";
import {Link} from "react-router-dom";
import {YearBadge} from "../YearBadge/YearBadge";

export const Events = ({match}) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    loadAccounts(setLoading).then(accounts => setEvents(accounts.map(a => a.events).flat()));
  }, []);

  return (
    <DashboardPage>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Header>
                <h1>Your Events</h1>
              </Card.Header>
              <Card.Body>
                {
                  loading
                    ? <LoadingRow/>
                    : events.map(e =>
                      <Row><Col><Link to={`/in/events/${e.id}`}>{e.name} <YearBadge>{e.year}</YearBadge></Link></Col></Row>
                    )
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </DashboardPage>
  );
};
