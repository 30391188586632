import React, {useState} from 'react';
import "./NewCategoryForm.css"
import {Button, Col, Form, Row} from "react-bootstrap";

export const NewCategoryForm = ({category: formCategory, cancel, saveCategory}) => {
  const [category, setCategory] = useState(formCategory);

  const save = () => saveCategory(category);

  const handleChange = (value, property) => {
    const update = Object.assign({}, category);
    update[property] = value;
    setCategory(update);
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "name"}
            type="text"
            value={category.name}
            onChange={e => handleChange(e.target.value, 'name')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Category
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "category"}
            type="text"
            value={category.category}
            onChange={e => handleChange(e.target.value, 'category')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Gender
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "gender"}
            as="select"
            value={category.gender}
            className="gender"
            onChange={e => handleChange(e.target.value, 'gender')}>
            {
              ['', 'male', 'female', 'any'].map(c => <option value={c}>{c}</option>)
            }
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Class
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "category_class"}
            type="text"
            value={category.category_class}
            onChange={e => handleChange(e.target.value, 'category_class')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Age Group
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "age_group"}
            type="text"
            value={category.age_group}
            onChange={e => handleChange(e.target.value, 'age_group')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Date
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "category_date"}
            type="text"
            value={category.category_date}
            onChange={e => handleChange(e.target.value, 'category_date')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Time Group
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "group"}
            type="text"
            value={category.group}
            onChange={e => handleChange(e.target.value, 'group')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Category Group
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "category_group"}
            type="text"
            value={category.category_group}
            onChange={e => handleChange(e.target.value, 'category_group')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Bib Number Start
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "bib_number_start"}
            type="text"
            value={category.bib_number_start}
            onChange={e => handleChange(e.target.value, 'bib_number_start')}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Bib Number Stop
        </Form.Label>
        <Col sm="9">
          <Form.Control
            autoFocus={category.editing === "bib_number_end"}
            type="text"
            value={category.bib_number_end}
            onChange={e => handleChange(e.target.value, 'bib_number_end')}
          />
        </Col>
      </Form.Group>
      <Row><Col sm="12"><Button onClick={save}>Create</Button><Button onClick={cancel}>Cancel</Button></Col></Row>
      <Row><Col><br /></Col></Row>
    </>
  );
};