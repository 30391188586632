import {csv, pdf} from "../../../services/api/api.service";


export const exportResults = ({id, name}, date, loading) => {
  const filename = date ? `${name}_${date}_usac_results.csv` : `${name}_all_usac_results`;
  const uri = date ? `/events/${id}/results?date=${date}` : `/events/${id}/results`;

  return csv({uri, filename, loading});
};


export const getBibLabels = ({id, name}, loading) => pdf({uri: `/events/${id}/bib_labels?options=race,bib_number`, loading, filename: `${name}_labels.pdf`});
export const getBibLabelsAlphabetical = ({id, name}, loading) => pdf({uri: `/events/${id}/bib_labels?options=last_name,first_name`, loading, filename: `${name}_labels.pdf`});


