import cookies from 'browser-cookies';

export const login = (user, token) => {
  cookies.set('token', token.toString());
  cookies.set('userId', user.id.toString());
  cookies.set('userName', user.name.toString());
};

export const logout = () => {
  cookies.erase('token');
  cookies.erase('userId');
  cookies.erase('userName');
};

export const isLoggedIn = () => !!cookies.get('token');
export const token = () => cookies.get('token');
export const user = () => ({ id: cookies.get('userId'), name: cookies.get('userName') });