import React, {useEffect, useState} from 'react';
import * as service from "./Entries.service";
import "./Entries.css"
import {ProgressBar} from "react-bootstrap";
import {EntriesForm} from "../EntriesForm/EntriesForm";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es";
import {RemoveIcon} from "../../../forms/RemoveIcon/RemoveIcon";
import {EntriesCategoryChangeForm} from "../EntriesCategoryChangeForm/EntriesCategoryChangeForm";

export const Entries = ({category, categories, onSave}) => {
  const [entries, setEntries] = useState([]);
  const [duplicateBibs, setDuplicateBibs] = useState([]);
  const [loading, setLoading] = useState(false);
  const sortEntries = entryArray => entryArray.sort((c, o) => c.rank_value - o.rank_value).sort((c, o) => c.bib_number - o.bib_number);

  const updateEntries = doWork => {
    setEntries(sortEntries(doWork(Object.assign([], entries))));
  };

  useEffect(() => {
    service.loadCategory(category.id, setLoading)
      .then(c =>
        setEntries(sortEntries(c.entries))
      );
  }, []);

  useEffect(
    () => {
      setDuplicateBibs(entries.reduce((a, e) => {
        let bibNumber = (e.bib_number) ? e.bib_number : '';
        return (a[bibNumber.toString().slice(-2)] = Object.keys(a).includes(bibNumber.toString().slice(-2)), a)
      }, {}))
    }, [entries]);

  const rowEdit = (index, focusOn, type) => {
    const update = Object.assign([], entries);
    update[index][type] = focusOn;
    setEntries(update);
  };

  const exitEdit = (index, type,) => {
    const update = Object.assign([], entries);
    delete update[index][type];
    if(!update[index]['id']) delete update[index];
    setEntries(update);
  };

  const addNewEntry = () => {
    updateEntries(entries => (entries.push({editing: 'bib_number', category_id: category.id, rank_value: 99999}), entries))
  };

  const saveEntry = (index, entry) =>
    service.saveEntry(entry)
      .then((responseEntry) => {
        if (entry.category_id === category.id) {
          const newEntry = {...entry, ...responseEntry};
          updateEntries(entries => (entries[index] = newEntry, entries));
          toast.success(`Saved ${newEntry.first_name} ${newEntry.last_name}'s entry`);
        }
        else {
          updateEntries(entries => (delete entries[index], entries));
          toast.success(`Moved ${entry.first_name} ${entry.last_name} to new category`);
        }
        onSave();
        return new Promise(resolve => resolve());
      });

  const removeEntry = (index, entry) => {
    updateEntries(entries => (entries[index]['deleting'] = true, entries));

    service.removeEntry(entry)
      .then(() => {
        updateEntries(entries => (delete entries[index], entries));
        toast.error(`Deleted ${entry.first_name} ${entry.last_name}'s entry`);
      }).catch(() => updateEntries(entries => (entries[index]['deleting'] = false, entries)))
  };

  return (
    <tbody className="list entries">
    {
      loading
        ? <tr>
          <td colSpan={6}><ProgressBar animated now={100}/></td>
        </tr>
        : entries.map((e, i) => {
          if (e.editing) {
            return <EntriesForm
              entry={e}
              saveEntry={e => saveEntry(i, e)}
              exitEdit={(creating) => exitEdit(i, 'editing', creating)}
              focusOn={e.editing}
            />
          } else if (e.category_change) {
            return <EntriesCategoryChangeForm
              entry={e}
              saveEntry={e => saveEntry(i, e)}
              exitEdit={() => exitEdit(i, 'category_change')}
              focusOn={e.editing}
              categories={categories}
            />
          } else {
            return <tr key={e.id}>
              <td onClick={() => rowEdit(i, 'bib_number', 'editing')}
                  className={duplicateBibs[(e.bib_number) ? e.bib_number.toString().slice(-2) : ''] ? 'duplicate-bib-number' : ''}>
                {e.bib_number}
              </td>
              <td onClick={() => rowEdit(i, 'license_number', 'editing')}>
                {e.license_number}
              </td>
              <td onClick={() => rowEdit(i, 'first_name', 'editing')}>
                {e.first_name}
              </td>
              <td onClick={() => rowEdit(i, 'last_name', 'editing')}>
                {e.last_name}
              </td>
              <td onClick={() => rowEdit(i, 'team', 'editing')}>
                {e.team}
              </td>
              <td onClick={() => rowEdit(i, 'rank_value', 'editing')}>
                {e.rank_value}
              </td>
              <td className="align-middle">
                <RemoveIcon remove={() => removeEntry(i, e)} spin={e.deleting}/>
                <span title='Move Entry' onClick={() => rowEdit(i, true, 'category_change')}>
                  <FontAwesomeIcon icon={faAngleDoubleRight} className="move" size="lg"/>
                </span>
              </td>
            </tr>
          }
        })
      }
      <tr>
        <td colSpan={7} className="add-new-entry" onClick={addNewEntry}>Click to add new entry</td>
      </tr>
    </tbody>
  );
}

