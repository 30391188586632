import React, {useState} from 'react';
import './Button.css';
import { Button as BootstrapButton } from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

export const Button = ({children, onClick, ...options}) => {
  const [spin, setSpin] = useState(false);

  const wrappedOnClick = (e) => {
    e.preventDefault();
    onClick(setSpin)
  };


  return (
    <BootstrapButton className="btn btn-primary my-4" onClick={wrappedOnClick} {...options} disabled={spin}>
      {spin && <FontAwesomeIcon className="button-spinner" icon={faCircleNotch} spin />}
      {children}
    </BootstrapButton>);
};