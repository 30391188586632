import React, {useEffect, useState} from 'react';
import './Categories.css';
import {assignNumbers, createCategory, loadEvent, saveCategory} from "./Categories.service";
import {Button, Card, Table} from "react-bootstrap";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {CategoryForm} from "./CategoryForm/CategoryForm";
import {toast} from "react-toastify";
import moment from "moment/moment";
import {NewCategoryForm} from "./NewCategoryForm/NewCategoryForm";

export const Categories = ({match}) => {
  const [event, setEvent] = useState({});
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assigning, setAssigning] = useState(false);

  const sort = a => (a.sort((a, b) => {
    let dayOne = moment(a.category_date);
    let dayTwo = moment(b.category_date);
    if(dayOne.isSame(dayTwo, 'days')) {
      return a.name > b.name ? 1 : -1;
    }
    return dayOne.valueOf() - dayTwo.valueOf();
  }), a);
  const updateCategories = doWork => setCategories(sort(doWork(Object.assign([], categories))));

  function load() {
    loadEvent(match.params.id, setLoading).then(e => {
      setEvent(e);
      updateCategories(() => e.categories);
    })
  }

  useEffect(() => load(), []);

  const rowEdit = (index, focusOn) => updateCategories(c => (c[index]['editing'] = focusOn, c));
  const exitEdit = (index) => updateCategories(c => (delete c[index]['editing'], c));

  const save = (index, category) =>
    saveCategory(category)
      .then(() => {
        updateCategories(c => (c[index] = category, c));
        toast.success(`Saved ${category.name}.`);
      });

  const saveNew = (category) => {
    console.log(category);
    createCategory(event, category).then(() => {
      toast.success(`Created ${category.name}.`);
      load();
      setNewCategory(null);
    });
  };


  const assignBibNumbers = () => assignNumbers(event.id, setAssigning).then((e) => toast.success(`Assigned numbers for ${event.name}.`)).catch(e => toast.error(e[0]));

  return (
    !event
      ? <div></div>
      : <DashboardPage title={event.name} year={event.year} pageTitle="Categories">
        <Card>
          <Card.Header>
            <Button onClick={assignBibNumbers}>Assign Bib Numbers</Button>
            <Button onClick={() => setNewCategory({})}>New Category</Button>
          </Card.Header>
          <Card.Body>
            {newCategory && <NewCategoryForm category={{}} cancel={() => setNewCategory(null)} saveCategory={saveNew}/>}

            <Table striped hover responsive>
              <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>USAC Category</th>
                <th>Gender</th>
                <th>Class</th>
                <th>Age Group</th>
                <th>Date</th>
                <th>Time Group</th>
                <th>Cat. Group</th>
                <th>Bib Start - End</th>
              </tr>
              </thead>
              <tbody>
              {
                categories.map((c, i) =>
                  c.editing
                    ? <CategoryForm category={c} exitEdit={() => exitEdit(i)} saveCategory={c => save(i, c)} />
                    : <tr key={c.id}>
                      <td onClick={() => rowEdit(i, 'name')}>{c.name}</td>
                      <td onClick={() => rowEdit(i, 'category')}>{c.category}</td>
                      <td onClick={() => rowEdit(i, 'gender')}>{c.gender}</td>
                      <td onClick={() => rowEdit(i, 'category_class')}>{c.category_class}</td>
                      <td onClick={() => rowEdit(i, 'age_group')}>{c.age_group}</td>
                      <td onClick={() => rowEdit(i, 'category_date')}>{c.category_date}</td>
                      <td onClick={() => rowEdit(i, 'group')}>{c.group}</td>
                      <td onClick={() => rowEdit(i, 'category_group')}>{c.category_group}</td>
                      <td onClick={() => rowEdit(i, 'bib_number_start')}>{c.bib_number_start} - {c.bib_number_end}</td>
                    </tr>
                )
              }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </DashboardPage>
  );
};
