import React, {useEffect, useState} from 'react';
import './Results.css';
import {combinedResultsPdf, loadEvent, resultsPdf} from "./Results.service";
import {Button, Card, Col, Collapse, Row} from "react-bootstrap";
import {CategoryDates} from "../CategoryDates/CategoryDates";
import moment from "moment/moment";
import {DateBadge} from "../DateBadge/DateBadge";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {LoadingRow} from "../../LoadingRow/LoadingRow";
import {CategoryResults} from "./CategoryResults/CategoryResults";

const uniq = (array) => Object.values(array.reduce((a, c) => (a[c.format()] = c, a), {}));

export const Results = ({match}) => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState({categories: []});
  const [collapse, setCollapse] = useState();
  const [allDates, setAllDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);

  const toggleRacerTable = (id) => {
    const copy = Object.keys(collapse).reduce((o, k) => (o[k] = false, o), {});
    copy[id] = !collapse[id];
    setCollapse(copy);
  };

  const downloadPdf = (e) => resultsPdf(e, () => {});
  const downloadCombinedPdf = (e) => combinedResultsPdf(e, () => {});

  useEffect(() => {
    loadEvent(match.params.id, setLoading)
      .then(e => {
        setEvent(e);
        let dates = uniq(e.categories.map(c => moment(c.category_date)));
        const dayToday = dates.filter(d => d.isSame(moment(), "day") );
        if (dayToday.length > 0) {
          setSelectedDates(dayToday);
        } else {
          setSelectedDates(dates);
        }
        setAllDates(dates);
      })
  }, []);

  useEffect(() => setCollapse(event.categories.reduce((o, c) => (o[c.id] = false, o), {})), [event]);

  useEffect(() => setFilteredCategories(event.categories.filter(c => selectedDates.filter(d => d.isSame(c.category_date)).length > 0).sort((a,b) => (a.name > b.name) ? 1 : -1)), [event, selectedDates]);

  return (
    !event
      ? <div></div>
      : <DashboardPage title={event.name} year={event.year} pageTitle="Results">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <Card className="bg-default">
              <Card.Header className="bg-transparent">
                <h6 className="text-light text-uppercase ls-1 mb-1">Filter Dates</h6>
                {loading
                  ? <LoadingRow/>
                  : <CategoryDates allDates={allDates} selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
                }
              </Card.Header>
            </Card>
          </Col>
        </Row>
        {
          loading
            ? <LoadingRow/>
            : filteredCategories.sort((a, b) => {
                  const timeA = a.group.toLowerCase();
                  const timeB = b.group.toLowerCase();

                  if (timeA < timeB) {
                      return -1;
                  } else if (timeA > timeB) {
                      return 1;
                  } else {
                      return 0;
                  }
              }).map((c) =>
              <div className="card" key={c.id}>
                <div className="card-header border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0"><DateBadge>{moment(c.category_date)}</DateBadge> {c.name} Results</h3>
                    </Col>
                    <Col className="text-right">
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => downloadCombinedPdf(c)}
                      >
                        Combined Time PDF
                      </Button>

                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => downloadPdf(c)}
                      >
                        Results PDF
                      </Button>

                      <Button
                        className="btn btn-sm btn-primary"
                        aria-controls={`category${c.id}`}
                        aria-expanded={collapse[c.id]}
                        onClick={() => toggleRacerTable(c.id)}
                      >
                        See all
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Collapse in={collapse[c.id]}>
                    <span>
                      {collapse[c.id] && <CategoryResults category={c} />}
                    </span>
                </Collapse>
              </div>
            )}
      </DashboardPage>
  );
};
