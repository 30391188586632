import {uploadFile} from "../../../services/api/api.service";


export const importBikeReg = (id, file, loading) =>
  uploadFile(
    {
      uri: `/events/${id}/import/bikereg`,
      method: 'post',
      file,
      loading
    });


