import React, {useEffect, useState} from 'react';
import * as service from "./CategoryResults.service";
import "./CategoryResults.css"
import {ProgressBar, Table, Button} from "react-bootstrap";
import {ResultForm} from "../ResultForm/ResultForm";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

export const CategoryResults = ({category}) => {
  const [results, setResults] = useState([]);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const sortResults = entryArray => entryArray.sort((c, o) => c.place.localeCompare(o.place, undefined, {
    numeric: true,
    sensitivity: 'base'
  }));
  const updateResults = doWork => setResults(sortResults(doWork(Object.assign([], results))));

  useEffect(() => {
    service.loadCategory(category.id, setLoading)
      .then(c => {
        setDoneLoading(true);
        setEntries(c.entries);
        setResults(sortResults(c.results));
      });
  }, []);

  const addNewRow = () => updateResults(r => {
    console.log(r)
    const updated = r.filter(i => i.bib_number !== null);
    updated.push(service.getNextPlace(r, category));
    return updated;
  });

  useEffect(() => {
    let result = results[results.length - 1];
    console.log(result)
    if (doneLoading && (results.length === 0 || (result['id'] !== undefined && result['entry_id'] !== undefined))) {
      addNewRow();
    }
  }, [results]);

  const saveResult = (result, index) => {
    service.saveResult(result)
      .then((responseResult) => {

        result = {...result, ...responseResult};
        updateResults(r => (r[index] = result, r));
        addNewRow();

        toast.success(`Saved result`);
      })
  };

  const removeResult = (result, index) => {
    updateResults(results => (results[index]['deleting'] = true, results));

    service.removeResult(result)
      .then(() => {
        updateResults(results => (results.splice(index, 1), results));
        toast.error(`Deleted result`);
      }).catch(() => updateResults(results => (results[index]['deleting'] = false, results)))
  };

  return (
    <>
      {results.length < 2 && entries.some(entry => entry.entry_laps && entry.entry_laps.length > 0) && (
        <>
        <Button 
          className="btn btn-sm btn-primary mb-3"
          onClick={async () => {
            setResults(await service.generateResultsFromLapsForCategory(category.id, setLoading))
          }}
        >
          Generate Results from Laps
        </Button>
        <br/>
        </>
      )}

      Available: {entries.reduce((o,e) => {
        if(results.filter(r => r.entry_id === e.id).length === 0) o.push(e);
        return o;
    },[]).sort((a, b) => {
      if (parseInt(a.bib_number) < parseInt(b.bib_number)) {
        return -1;
      } else if (parseInt(a.bib_number) > parseInt(b.bib_number)) {
        return 1;
      } else {
        return 0;
      }
    }).map(e => (<><button onClick={() => {
      let r = results[results.length - 1];
      let entry = entries.filter(a => a.bib_number === e.bib_number)[0];
      r.entry = entry ? entry : {id: null};
      r.entry.bib_number = e.bib_number;
      r.entry_id = r.entry.id;
      saveResult(r, results.length - 1);
    }}>{e.bib_number}</button>&nbsp;&nbsp;</>)).filter(b => !!b)}

      <Table striped hover responsive>
        <thead className="thead-light">
        <tr>
          <th>Place</th>
          <th>Bib</th>
          <th>License Number</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Team</th>
          <th></th>
        </tr>
        </thead>
        <tbody className="list entries">
        {
          loading
            ? <tr>
              <td colSpan={6}><ProgressBar animated now={100}/></td>
            </tr>
            : results.map((r, i) =>
              <ResultForm
                key={`${r.id}${r.place}`}
                result={r}
                entries={entries}
                saveResult={r => saveResult(r, i)}
                addNewRow={addNewRow}
                removeResult={() => removeResult(r, i)}
              />)
        }
        </tbody>
      </Table>
    </>
  );
}

