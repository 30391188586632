import React, {useState} from 'react';
import "./CategoryForm.css"
import {Form} from "react-bootstrap";
import debounce from "debounce-promise";

const onBlur = (e, exit, savePromise) => {
  let currentTarget = e.currentTarget;

  savePromise.then(() => {
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          exit()
        }
      }, 1500)
  }
  )
};

const save = (entry, setPromise, save) => save(entry).then(() => Promise.resolve());
const debounceSave = debounce(save, 1000);

export const CategoryForm = ({category: formCategory, exitEdit, saveCategory}) => {
  const [category, setCategory] = useState(formCategory);
  const [savePromise, setSavePromise] = useState(new Promise(r => r()));

  const handleChange = (value, property) => {
    setCategory({...category, [property]: value});
    setSavePromise(debounceSave({...category, [property]: value}, setSavePromise, saveCategory));
  };

  return (
    <tr onBlur={e => onBlur(e, exitEdit, savePromise)}>
      <td>
        <Form.Control
          autoFocus={category.editing === "name"}
          type="text"
          value={category.name}
          onChange={e => handleChange(e.target.value, 'name')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "category"}
          type="text"
          value={category.category}
          onChange={e => handleChange(e.target.value, 'category')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "gender"}
          as="select"
          value={category.gender}
          className="gender"
          onChange={e => handleChange(e.target.value, 'gender')}>
          {
            ['', 'male', 'female', 'any'].map(c => <option value={c}>{c}</option>)
          }
        </Form.Control>
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "category_class"}
          type="text"
          value={category.category_class}
          onChange={e => handleChange(e.target.value, 'category_class')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "age_group"}
          type="text"
          value={category.age_group}
          onChange={e => handleChange(e.target.value, 'age_group')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "category_date"}
          type="text"
          value={category.category_date}
          onChange={e => handleChange(e.target.value, 'category_date')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "group"}
          type="text"
          value={category.group}
          onChange={e => handleChange(e.target.value, 'group')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "category_group"}
          type="text"
          value={category.category_group}
          onChange={e => handleChange(e.target.value, 'category_group')}
        />
      </td>
      <td>
        <Form.Control
          autoFocus={category.editing === "bib_number_start"}
          type="text"
          value={category.bib_number_start}
          onChange={e => handleChange(e.target.value, 'bib_number_start')}
        />
        -
        <Form.Control
          autoFocus={category.editing === "bib_number_end"}
          type="text"
          value={category.bib_number_end}
          onChange={e => handleChange(e.target.value, 'bib_number_end')}
        />
      </td>
    </tr>
  );
};