import React from 'react';
import {Form} from "react-bootstrap";

const adaptFileEventToValue = delegate => e => { console.log(e.target.files[0]); delegate(e.target.files[0]);}



const FileInput = ({
                     value: omitValue,
                     onChange,
                     ...props
                   }) =>
  (<Form.Control
    onChange={adaptFileEventToValue(onChange)}
    type="file"
    {...props}
  />);

export default FileInput;