import React, {useEffect} from 'react';
import './CategoryDates.css';
import {Button, Col, Row} from "react-bootstrap";
import moment from "moment";

const chunk = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

export const CategoryDates = ({allDates, selectedDates, setSelectedDates}) => {
  const toggleDate = (date) => {
    const updated = Object.assign(selectedDates.filter(d => !d.isSame(date)), []);

    if(selectedDates.filter(d => d.isSame(date)).length === 0) {
      updated.push(date);
    }
    setSelectedDates(updated);
  };

  const variant = (date) => selectedDates.includes(date) ? 'primary' : 'secondary';

  return (
    chunk(allDates, 2).map((dates, i) => (
      <Row className="my-4" key={i}>
        <Col>
          {dates.sort((a, b) => a.valueOf() - b.valueOf()).map(d => <Button onClick={() => toggleDate(d)} variant={variant(d)} key={d.format("MM/DD/YYYY")}>{d.format("MM/DD/YYYY")}</Button>)}
        </Col>
      </Row>
      )
    )
  );
};
