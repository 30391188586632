import {api} from "../../../services/api/api.service";

export const loadEvent = (id, loading) => api({uri: `/events/${id}`, loading});
export const assignNumbers = (id, loading) => api({ method: 'post', uri: `/events/${id}/assign_bib_numbers`, loading});
export const saveCategory = ({id, editing, ...category}) =>
  api(
      {
        method: 'patch',
        uri: `/categories/${id}`,
        body: {category},
        loading: () => {
        }
      });
export const createCategory = ({id}, category) =>
  api(
    {
      method: 'post',
      uri: `/events/${id}/categories`,
      body: {category},
      loading: () => {}
    });