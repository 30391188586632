import React, {useState} from 'react';
import {Col, Nav, Navbar, Row} from "react-bootstrap";
import {logout as userLogout, user} from "../../../services/user/user.service";
import {YearBadge} from "../YearBadge/YearBadge";

const logout = (setLogin) => {
  userLogout();
  setLogin(true);
};

export const DashboardPage = ({title, year, pageTitle, children}) => {
    const [login, setLogin] = useState(false);

    return (<div className="main-content" id="panel">
    <Navbar className="navbar-top navbar-expand navbar-dark bg-primary border-bottom">
      <Nav className="mr-auto d-none d-lg-block">
        <Nav.Link>
          <h6 className="h2 text-white d-inline-block mb-0 ml-md-auto">{title}</h6>
          &nbsp;&nbsp;{year && <YearBadge>{year}</YearBadge>}
        </Nav.Link>
      </Nav>

      <Nav className="mr-auto d-lg-none d-xl-none align-items-center">
        <Nav.Link>
          <div className="pr-3 sidenav-toggler sidenav-toggler-dark"
               data-action="sidenav-pin"
               data-target="#sidenav-main">
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line"/>
              <i className="sidenav-toggler-line"/>
              <i className="sidenav-toggler-line"/>
            </div>
          </div>
        </Nav.Link>
        <Nav.Link>
          <h6 className="h2 text-white d-inline-block mb-0 ml-md-auto">{title}</h6>
          &nbsp;&nbsp;{year && <YearBadge>{year}</YearBadge>}
        </Nav.Link>
      </Nav>

      <Nav className="mn-auto">
        <Nav.Link>
          {user().name} - <span onClick={() => logout(setLogin)}>Logout</span>
        </Nav.Link>
      </Nav>
    </Navbar>

    <div className="header bg-primary pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-6 col-7">
              <h1 className="text-white d-inline-block mb-0 ml-md-auto">{pageTitle}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid mt--6">

      <Row>
        <Col>
          {children}
        </Col>
      </Row>

      <footer className="footer pt-0">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6">
            <div className="copyright text-center text-lg-left text-muted">
              &copy; 2019 <a href="https://www.google.com" className="font-weight-bold ml-1"
                             target="_blank" rel="noopener noreferrer">cogsandcarbon</a>
            </div>
          </div>
          <div className="col-lg-6">
            <ul className="nav nav-footer justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a href="https://www.google.com" className="nav-link" target="_blank">Legal</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
)
          }
