import React, {useEffect, useState} from 'react';
import "./ResultForm.css"
import {Button, Form} from "react-bootstrap";
import {debounce} from "debounce";
import {RemoveIcon} from "../../../forms/RemoveIcon/RemoveIcon";

const save = (entry, save) => {
  if(entry.entry_id) save(entry);
};
const debounceSave = debounce(save, 1000);

export const ResultForm = ({result: formResult, entries, saveResult, removeResult}) => {
  const [result, setResult] = useState(formResult);
  const updateResult = doWork => (setResult(doWork(Object.assign({}, result))), result);

  const handleChange = (value, property) => {
    updateResult(r => {
      if(property === 'bib_number') {
        let entry = entries.filter(e => e.bib_number === value)[0];
        r.entry = entry ? entry : {id: null};
        r.entry.bib_number = value;
        r.entry_id = r.entry.id;
        console.log(r)
      } else {
        r[property] = value;
      }

      debounceSave(r, saveResult);
      return r;
    });
  };

  return (
    <tr>
      <td>
        <Form.Control
          type="text"
          value={result.place}
          className="bib_number"
          onChange={e => handleChange(e.target.value, 'place')}
        />
      </td>
      <td>
        <Form.Control
          type="text"
          value={result.entry.bib_number}
          className="bib_number"
          onChange={e => handleChange(e.target.value, 'bib_number')}
        />
      </td>
      <td className="align-middle">
        {result.entry.license_number}
      </td>
      <td className="align-middle">
        {result.entry.first_name}
      </td>
      <td className="align-middle">
        {result.entry.last_name}
      </td>
      <td className="align-middle">
        {result.entry.team}
      </td>
      <td className="align-middle"><RemoveIcon remove={removeResult} spin={result.deleting}/></td>
    </tr>
  );
};