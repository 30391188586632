import React, { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Button } from 'react-bootstrap';
import { DashboardPage } from '../DashboardPage/DashboardPage';
import { LoadingRow } from '../../LoadingRow/LoadingRow';
import { loadCategory,lapEntry } from '../CategoryEntries/Entries/Entries.service';

export const Laps = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const entries = category ? category.entries : [];

  useEffect(() => {
    loadCategory(match.params.categoryId, setLoading)
      .then(categoryData => {
        setCategory(categoryData);
        setLoading(false);
      });
  }, [match.params.categoryId]);

  const addLap = (entry) => {
    console.log(entry);
    lapEntry(entry.id).then(({lap}) => {
      // Find the entry in the category and update its lap count
      const updatedEntries = category.entries.map(e => {
        if (e.id === entry.id) {
          return {
            ...e,
            entry_laps: e.entry_laps ? [...e.entry_laps, lap] : [lap]
          };
        }
        return e;
      });

      // Update the category state with the new entries
      setCategory({
        ...category,
        entries: updatedEntries
      });
    });
  };

  const renderEntryRow = (entry) => (
    <>
        <Button 
          variant="outline-primary" 
          size="lg" 
          onClick={() => addLap(entry)}
        >
          {entry.bib_number} ({entry.entry_laps ? entry.entry_laps.length : 0})
        </Button>
        <br/>
        <br/>
    </>
  );

  const maxLaps = Math.max(...entries.map(e => e.entry_laps ? e.entry_laps.length : 0));
  const sortByBibNumberAndOldestLapTime = (a, b) => {
    // First, sort by bib_number
    const bibNumberA = parseInt(a.bib_number, 10);
    const bibNumberB = parseInt(b.bib_number, 10);
    if (bibNumberA !== bibNumberB) {
      return bibNumberA - bibNumberB;
    }
    
    // If bib numbers are the same, sort by oldest lap time
    const aTime = a.entry_laps && a.entry_laps.length > 0 ? new Date(a.entry_laps[0].time) : new Date(0);
    const bTime = b.entry_laps && b.entry_laps.length > 0 ? new Date(b.entry_laps[0].time) : new Date(0);
    return aTime - bTime;
  };

  const leadLapEntries = entries
    .filter(e => e.entry_laps && e.entry_laps.length === maxLaps)
    .sort(sortByBibNumberAndOldestLapTime);

  const lappedEntries = entries
    .filter(e => !e.entry_laps || e.entry_laps.length < maxLaps)
    .sort(sortByBibNumberAndOldestLapTime);


  console.log("DOOO", maxLaps, leadLapEntries, lappedEntries);

  return (
    <DashboardPage title={category ? category.name : 'Loading...'} pageTitle="Laps">
        <div className="card">
        <div className="card-header border-0">
    <Row>
    <Col xs={4}>
              <h4>Lapped</h4>
              {lappedEntries.map(renderEntryRow)}
            </Col>
            <Col xs={4}>
              <h4>Lead Lap</h4>
              {leadLapEntries.map(renderEntryRow)}
            </Col>
    </Row>
    </div></div>
    </DashboardPage>
  );
};
