import React, {useState} from 'react';
import "./EntriesForm.css"
import {Button, Col, Form, Row} from "react-bootstrap";
import {debounce} from "debounce";


const onBlur = (e, exit) => {
  let currentTarget = e.currentTarget;

  setTimeout(() => {
    if (!currentTarget.contains(document.activeElement)) {
      exit()
    }
  }, 1500)
};

const save = (entry, save) => save(entry);
const debounceSave = debounce(save, 1000);

export const EntriesForm = ({entry: formEntry, exitEdit, saveEntry, focusOn}) => {
  const [entry, setEntry] = useState(formEntry);
  const [disable, setDisable] = useState(false);

  const handleChange = (value, property) => {
    const update = Object.assign({}, entry);
    update[property] = value;
    setEntry(update);

    if (update.id) debounceSave(update, saveEntry);
  };

  const createEntry = () => {
    saveEntry(entry);
    setDisable(true);
  };

  return (
    <tr onBlur={e => onBlur(e, exitEdit)}>
      {entry.id
        ?
        <>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "bib_number"}
              type="text"
              value={entry.bib_number}
              className="bib_number"
              onChange={e => handleChange(e.target.value, 'bib_number')}
            />
          </td>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "license_number"}
              type="text"
              value={entry.license_number}
              className="license_number"
              onChange={e => handleChange(e.target.value, 'license_number')}
            />
          </td>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "first_name"}
              type="text"
              value={entry.first_name}
              className="first_name"
              onChange={e => handleChange(e.target.value, 'first_name')}
            />
          </td>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "last_name"}
              type="text"
              value={entry.last_name}
              className="last_name"
              onChange={e => handleChange(e.target.value, 'last_name')}
            />
          </td>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "team"}
              type="text"
              value={entry.team}
              className="team"
              onChange={e => handleChange(e.target.value, 'team')}
            />
          </td>
          <td>
            <Form.Control
              disabled={disable}
              autoFocus={focusOn === "rank_value"}
              type="text"
              value={entry.rank_value}
              className="rank_value"
              onChange={e => handleChange(e.target.value, 'rank_value')}
              required
            />
          </td>
          <td>
            {!entry.id && <Button onClick={createEntry}>Create</Button>}
          </td>
        </>
        :
        <td colSpan="7">
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Bib Number
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "bib_number"}
                type="text"
                value={entry.bib_number}
                className="bib_number"
                onChange={e => handleChange(e.target.value, 'bib_number')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              License Number
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "license_number"}
                type="text"
                value={entry.license_number}
                className="license_number"
                onChange={e => handleChange(e.target.value, 'license_number')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              First Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "first_name"}
                type="text"
                value={entry.first_name}
                className="first_name"
                onChange={e => handleChange(e.target.value, 'first_name')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Last Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "last_name"}
                type="text"
                value={entry.last_name}
                className="last_name"
                onChange={e => handleChange(e.target.value, 'last_name')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Team
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "team"}
                type="text"
                value={entry.team}
                className="team"
                onChange={e => handleChange(e.target.value, 'team')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Rank Value
            </Form.Label>
            <Col sm="10">
              <Form.Control
                disabled={disable}
                autoFocus={focusOn === "rank_value"}
                type="text"
                value={entry.rank_value}
                className="rank_value"
                onChange={e => handleChange(e.target.value, 'rank_value')}
              />
            </Col>
          </Form.Group>
          <Row>
            <Col>
              {!entry.id && <Button onClick={createEntry}>Create</Button>}
            </Col>
          </Row>
        </td>
      }
    </tr>
  );
};