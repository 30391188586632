import React, {useEffect, useState} from 'react';
import './NavBar.css';
import {loadAccounts} from "./NavBar.service";
import {YearBadge} from "../YearBadge/YearBadge";
import {LoadingRow} from "../../LoadingRow/LoadingRow";

export const NavBar = () => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAccounts(setLoading).then(setAccounts)
  }, []);

  return (
    <nav className="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          COGSANDCARBON
          <div className="ml-auto">
            <div className="sidenav-toggler d-none d-xl-block" data-action="sidenav-unpin"
                 data-target="#sidenav-main">
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <div className="collapse navbar-collapse" id="sidenav-collapse-main">

            {
              loading
                ? <LoadingRow/>
                : accounts.map((a) =>
                  (
                    <React.Fragment key={a.id}>
                      <h6 className="navbar-heading p-0 text-muted">{a.name}</h6>
                      <ul className="navbar-nav mb-md-3">
                        {a.events.map(e => (
                            <li className="nav-item" key={e.id}>
                              <a className="nav-link active" href={`#navbar-dashboards-${e.id}`} data-toggle="collapse" role="button"
                                 aria-expanded="true" aria-controls={`navbar-dashboards-${e.id}`}>
                                <span className="nav-link-text">{e.name} <YearBadge>{e.year}</YearBadge></span>
                              </a>
                              <div className="collapse show" id={`navbar-dashboards-${e.id}`}>
                                <ul className="nav nav-sm flex-column">
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/categories`} className="nav-link">Categories</a>
                                  </li>
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/entries`} className="nav-link">Entries</a>
                                  </li>
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/results`} className="nav-link">Results</a>
                                  </li>
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/import`} className="nav-link">Import</a>
                                  </li>
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/export`} className="nav-link">Export</a>
                                  </li>
                                  <li className="nav-item">
                                    <a href={`/in/events/${e.id}/stats`} className="nav-link">Stats</a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          )
                        )}
                      </ul>
                    </React.Fragment>
                  )
                )
            }
          </div>
        </div>
      </div>
    </nav>
  );
}
