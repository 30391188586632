import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import App from '../App/App';
import {DashboardApp} from "../Dashboard/DashboardApp/DashboardApp";

export const Routes = ({ userRole, ...props }) => (
  <BrowserRouter {...props}>
    <Switch>
      <Route path="/in" component={DashboardApp} />

      <Route path="/" component={App} />
    </Switch>
  </BrowserRouter>
);
