import React, {useEffect, useState} from 'react';
import './Stats.css';
import {loadStats, loadEvent} from "./Stats.service";
import {Card, Col, Row} from "react-bootstrap";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {LoadingRow} from "../../LoadingRow/LoadingRow";
import moment from "moment";

export const Stats = ({match}) => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState({});
  const [stats, setStats] = useState({ uniques: {}});

  useEffect(() => {
    loadStats(match.params.id, setLoading).then(setStats);
    loadEvent(match.params.id, setLoading).then(setEvent);
  }, []);

  return (
    <DashboardPage title={event.name} year={event.year} pageTitle="Results">
      {
        loading
          ? <LoadingRow/>
          :
          <>
            <Row>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Header>
                    <h1>Pre-Registered Racers</h1>
                  </Card.Header>
                  <Card.Body>
                    {stats.preRegistered}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Header>
                    <h1>Day Of Racers</h1>
                  </Card.Header>
                  <Card.Body>
                    {stats.dayOfRegistered}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Header>
                    <h1>Assigned Bibs vs Unique Pre-Registrants</h1>
                  </Card.Header>
                  <Card.Body>
                    {stats.numberOfBibNumbers} vs {stats.uniquePreRegistredRacers}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <Card>
                  <Card.Header>
                    <h1>No Bib # Assigned</h1>
                  </Card.Header>
                  <Card.Body>
                    {stats.noBibNumbers}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              {Object.keys(stats.uniques).map(k =>
                <Col xs={12} md={6} lg={3}>
                  <Card>
                    <Card.Header>
                      <h1>{moment(k).format("MM/DD/YYYY")}</h1>
                    </Card.Header>
                    <Card.Body>
                      <b>Unique:</b> {stats.uniques[k]} <br />
                      <b>Total:</b> {stats.totals[k]} <br />
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </>
      }
    </DashboardPage>
  );
};
