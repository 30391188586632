import React from 'react';
import './App.css';
import {Footer} from "../Footer/Footer";
import {Redirect, Route, Switch} from "react-router-dom";
import {Login} from "../Login/Login";

function App() {

  return (
    <div className="App">
      <nav id="navbar-main"
           className="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="/home">cogsandcarbon</a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse"
                  aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="navbar-collapse navbar-custom-collapse collapse" id="navbar-collapse">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <a href="/home">cogsandcarbon</a>
                </div>
                <div className="col-6 collapse-close">
                  <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse"
                          aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span/>
                    <span/>
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="/home" className="nav-link"><span className="nav-link-inner--text">Home</span></a>
              </li>
              <li className="nav-item">
                <a href="/pricing" className="nav-link"><span className="nav-link-inner--text">Pricing</span></a>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link"><span className="nav-link-inner--text">Login</span></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
      <div className="main-content">

        <div className="header bg-gradient-primary py-7 py-lg-8 pt-lg-9"/>

        <Switch>
          <Route exact path="/login" component={Login} />
        </Switch>
      </div>

      <Footer/>
    </div>
  );
}

export default App;
