import React, {useState} from 'react';
import './Login.css';
import {Alert, Form} from "react-bootstrap";
import {TextInput} from "../forms/TextInput/TextInput";
import {PasswordInput} from "../forms/PasswordInput/PasswordInput";
import {login} from "./Login.service";
import {Redirect} from "react-router-dom";
import {isLoggedIn} from "../../services/user/user.service";
import {Button} from "../forms/Button/Button";

export const Login = () => {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const formLogin = () => login(email, password)
      .then(() => {
        setError('');
        return ({cancel: true});
      })
      .catch(e => setError(e.error));

  if ( isLoggedIn() ) return <Redirect to="/in" />;

  return (
      <div className="container mt--8 pb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="card bg-secondary border-0 mb-0">
              <div className="card-body px-lg-5 py-lg-5">
                {error && <Alert variant='danger'>{error}</Alert>}
                <Form>
                  <div className="form-group mb-3">
                    <div className="input-group input-group-merge input-group-alternative">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><i className="ni ni-email-83"/></span>
                      </div>
                      <TextInput placeholder="Email" onChange={setEmail} />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group input-group-merge input-group-alternative">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><i className="ni ni-lock-circle-open"/></span>
                      </div>
                      <PasswordInput placeholder="Password" onChange={setPassword} />
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="btn btn-primary my-4" type="submit" onClick={formLogin}>Sign in</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};