import React, {useState} from 'react';
import './RemoveIcon.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import {faBan} from "@fortawesome/free-solid-svg-icons/index";
import {Button} from "react-bootstrap";

export const RemoveIcon = ({remove, spin}) => {
  const [areYouSure, setAreYouSure] = useState(false);

  const buttonAction = () => areYouSure ? remove() : setAreYouSure(true);

  const deleteButton = () => {
    if(areYouSure) {
      setTimeout(() => setAreYouSure(false), 5000);
      return <Button variant="danger" className="areYouSureButton" size="sm">Sure?</Button>;
    }
    return <FontAwesomeIcon icon={faBan} className="deleteIcon" size="lg"/>;
  };


  return (
    <span title='Remove Entry' onClick={buttonAction}>
      {
        spin
        ? <FontAwesomeIcon className="button-spinner" icon={faCircleNotch} spin />
        : deleteButton()
      }
    </span>);
};