import rp from 'request-promise';
import { token } from "../user/user.service";
import { saveAs } from 'browser-filesaver';

// export const domain = (url) => window.location.href.includes('localhost') ? `http://localhost:3000${url}` : `//api.begouden.com${url}`;
export const domain = (url) => window.location.href.includes('https') ? `https://api.begouden.com${url}` : `http://api.begouden.com${url}`;

export const uploadFile = ({ uri, file, loading, ...options }) => {
  loading(true);

  return fetch(domain(uri), {
    headers: {
      Authorization: token(),
    },
    body: getFormData(file),
    ...options
  }).then(e => {
    if (e.status !== 201) throw ({ error: "Errors happened :(" });
  })
    .finally(() => loading(false))
};

export const api = ({ uri, loading, ...options }) => {
  loading(true);

  return rp({
    uri: domain(uri),
    json: true,
    headers: {
      Authorization: token()
    },
    ...options
  })
    .catch((e) => { if (e.response.toJSON().body.errors) { throw (e.response.toJSON().body.errors) } throw ({ error: "Errors happened :(" }) })
    .finally(() => loading(false));
};


function getFile(loading, uri, Accept, options, filename) {
  loading(true);

  return fetch(domain(uri), {
    method: 'get',
    headers: {
      Authorization: token(),
      Accept,
    },
    ...options
  }).then(response => response.blob().then(r => saveAs(r, filename)))
    .finally(() => loading(false));
}

export const pdf = ({ uri, loading, filename, ...options }) => {
  return getFile(loading, uri, 'application/pdf', options, filename);
};

export const csv = ({ uri, filename, loading, ...options }) => {
  return getFile(loading, uri, 'text/csv', options, filename);
};


const getFormData = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return formData;
};
