import rp from 'request-promise';
import { login as localLogin } from '../../services/user/user.service';
import {domain} from "../../services/api/api.service";


export const login = (username, password) =>
    rp({
      method: 'post',
      uri: domain('/authenticate'),
      json: true,
      body: {
        username,
        password
      }
    }).then((r) => {
      localLogin(r.user, r.token)
    }).catch((e) => { throw({ error: "Couldn't log you in, please verify your username and password." }) });