import React, {useState} from 'react';
import "./EntriesCategoryChangeForm.css"
import {Form} from "react-bootstrap";
import {debounce} from "debounce";
import moment from "moment";


const onBlur = (e, exit) => {
  let currentTarget = e.currentTarget;

  setTimeout(() => {
    if (!currentTarget.contains(document.activeElement)) {
      exit()
    }
  }, 1500)
};

const save = (entry, save) => save(entry);
const debounceSave = debounce(save, 1000);

export const EntriesCategoryChangeForm = ({entry: formEntry, exitEdit, saveEntry, focusOn, categories}) => {
  const [entry, setEntry] = useState(formEntry);
  const sortedCategories = categories.sort((a,b) => {
    let dayOne = moment(a.category_date);
    let dayTwo = moment(b.category_date);
    if(dayOne.isSame(dayTwo, 'days')) {
      return a.name > b.name ? 1 : -1;
    }
    return dayOne.valueOf() - dayTwo.valueOf();
  });

  const handleChange = (value, property) => {
    const update = Object.assign({}, entry);
    update[property] = value;
    setEntry(update);
    debounceSave(update, saveEntry);
  };

  return (
    <tr onBlur={e => onBlur(e, exitEdit)}>
      <td className="align-middle">
        {entry.bib_number}
      </td>
      <td  className="align-middle">
        {entry.first_name}
      </td>
      <td  className="align-middle">
        {entry.last_name}
      </td>
      <td colSpan={4}>
        <Form.Control
          autoFocus
          as="select"
          value={entry.category_id}
          onChange={e => handleChange(e.target.value, 'category_id')}>
          {
            sortedCategories.map(c => <option value={c.id}>{moment(c.category_date).format('MM/DD/YY')} - {c.name} </option>)
          }
        </Form.Control>
      </td>
    </tr>
  );
};