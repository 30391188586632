import React, {useEffect, useState} from 'react';
import './Export.css';
import {Card, Col, Row} from "react-bootstrap";
import {DashboardPage} from "../DashboardPage/DashboardPage";
import {Button} from "../../forms/Button/Button";
import {loadEvent} from "../Categories/Categories.service";
import {LoadingRow} from "../../LoadingRow/LoadingRow";
import {Redirect} from "react-router-dom";
import moment from "moment/moment";
import {exportResults, getBibLabels, getBibLabelsAlphabetical} from "./Export.service";

const uniq = (array) => Object.values(array.reduce((a, c) => (a[c.format()] = c, a), {}));

export const Export = ({match}) => {
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState();
  const [dates, setDates] = useState([]);
  useEffect(() => {
    loadEvent(match.params.id, setLoading)
      .then(e => {
        setDates(uniq(e.categories.map(c => moment(c.category_date))));
        setEvent(e);
      })
  }, []);

  const getExport = (date, setSpin) =>
    exportResults(event, date, setSpin)
    .then(() => ({cancel: true}));

  const getLabels = (setSpin) => getBibLabels(event, setSpin).then(() => ({cancel: true}));
  const getLabelsAlphabetical = (setSpin) => getBibLabelsAlphabetical(event, setSpin).then(() => ({cancel: true}));


  return (
    !event || loading
      ? <Card><Card.Body><LoadingRow/></Card.Body></Card>
      : <DashboardPage title={event.name} year={event.year} pageTitle="Export">
          <Row>
            <Col>
              <Card>
                <Card.Header><h1>Export Labels for Bib Numbers (Avery 5160)</h1></Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Row>
                      <Col>
                        <Button onClick={(setSpin) => getLabels(setSpin)}>Category Sorted</Button>
                        <Button onClick={(setSpin) => getLabelsAlphabetical(setSpin)}>Alphabetical Sorted</Button>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header><h1>Export for USAC Results</h1></Card.Header>
              <Card.Body>
                <Card.Text>
                  <Row>
                    <Col>
                      {dates.map(d =>
                        <Button key={d.format("MM/DD/YYYY")} onClick={(setSpin) => getExport(d.format("YYYY-MM-DD"), setSpin)}>
                          {d.format("MM/DD/YYYY")}
                        </Button>)
                      }
                      <Button onClick={(setSpin) => getExport(undefined, setSpin)}>All Days For USAC</Button>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </DashboardPage>
  );
};
